@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&amp;family=Poppins:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&amp;display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,300;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800&amp;display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lato:wght@900&family=Poppins:wght@400;600;700&display=swap");
@import url("https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css");
@import "./assets/css/custom.css";
@import "./assets/css/plugins.css";

.designation {
  font-size: 76px;
  max-width: 700px;
}

h3.stroke {
  font-size: 96px;
}

.arrow_scroll {
  position: fixed;
  top: calc(100vh - 100px);
}

.copyright_inner ul > li > span > svg {
  vertical-align: middle;
  margin-right: 5px;
}
